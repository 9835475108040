var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "padding": "20px 90px",
      "paths": [{
        label: 'Beranda',
        href: {
          name: 'nutrisionist.managementClient'
        }
      }, {
        label: 'Manajemen Klien',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1200px",
      "mx": "auto"
    }
  }, [_c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px",
      "text-align": "center"
    }
  }, [_vm._v(" Manajemen Klien ")])], 1), _c('c-box', {
    attrs: {
      "margin-top": "30px",
      "display": "flex",
      "gap": "16px",
      "align-items": "center"
    }
  }, [_c('c-input-group', {
    attrs: {
      "w": "100%",
      "size": "lg"
    }
  }, [_c('c-input-left-element', [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon"
    }
  })], 1), _c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Pencarian",
      "border-radius": "20px"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('c-box', [_vm._v(" Show: ")]), _c('c-box', [_c('c-flex', {
    attrs: {
      "justify-content": "space-between"
    }
  }, [_c('c-menu', {
    attrs: {
      "close-on-select": true
    }
  }, [_c('c-menu-button', {
    attrs: {
      "border-radius": "40px",
      "background-color": "superLightGray.900",
      "pl": "20px",
      "pr": "30px",
      "py": "10px",
      "h": "40px",
      "color": "gray.900",
      "font-size": "14px",
      "line-height": "21px",
      "font-family": "Roboto",
      "font-weight": "normal",
      "display": ['none', 'flex']
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-filter.svg'),
      "alt": "icon",
      "display": "inline-block",
      "margin-right": "5px"
    }
  }), _vm._v(" Filter ")], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10",
      "min-width": "240px"
    }
  }, [_c('c-menu-option-group', {
    attrs: {
      "default-value": "Semua",
      "type": "radio"
    },
    on: {
      "change": function change($event) {
        _vm.status = $event;
      }
    }
  }, _vm._l(_vm.statuses, function (item) {
    return _c('c-menu-item-option', {
      key: item.value,
      attrs: {
        "value": item.value,
        "border-bottom": "1px solid #F2F2F2",
        "font-size": "18px",
        "line-height": "27px",
        "py": "16px",
        "px": "20px"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1)], 1), false ? _c('c-button', {
    attrs: {
      "border-radius": "20px",
      "background-color": "superLightGray.900",
      "px": "15px",
      "py": "4px",
      "color": "gray.900",
      "font-size": "16px",
      "line-height": "24px",
      "font-family": "Roboto",
      "font-weight": "700",
      "display": ['none', 'inline-block']
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-filter.svg'),
      "alt": "icon",
      "display": "inline-block",
      "margin-right": "5px"
    }
  }), _vm._v(" Filter ")], 1) : _vm._e()], 1), _c('c-button', {
    attrs: {
      "border-radius": "0",
      "background-color": "transparent",
      "px": "15px",
      "py": "4px",
      "color": "gray.900",
      "font-size": "16px",
      "line-height": "24px",
      "font-family": "Roboto",
      "font-weight": "700",
      "display": ['inline-block', 'none']
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-filter.svg'),
      "alt": "icon",
      "display": "inline-block",
      "margin-right": "5px"
    }
  })], 1)], 1)], 1), _c('c-grid', {
    attrs: {
      "w": "100%",
      "px": ['20px', '0'],
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "grid-gap": [0, '10px'],
      "margin-top": "30px"
    }
  }, _vm._l(_vm.clients, function (client) {
    return _c('CardClient', {
      key: client.id,
      attrs: {
        "status": _vm.cardClientStatus(client.programs.status),
        "name": "".concat(client.firstName, " ").concat(client.lastName),
        "photo": client.photoUrl,
        "program-name": client.programs.name,
        "phone": client.phone,
        "email": client.email
      },
      on: {
        "on-click": function onClick() {
          _vm.$router.push({
            name: 'nutri.detail-client',
            params: {
              clientId: client.id
            }
          });
        }
      }
    });
  }), 1), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "w": "100%",
      "margin-top": "40px"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center"
    }
  }, [_c('btn-pagination', {
    attrs: {
      "bg": "transparent",
      "disabled": _vm.currentPage === 1
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "size": "24px"
    }
  })], 1), _vm._l(_vm.pages, function (page) {
    return _c('btn-pagination', {
      key: page,
      attrs: {
        "bg": page === _vm.currentPage ? ['rgba(0, 140, 129, 0.3)'] : ['transparent'],
        "as": "router-link",
        "to": {
          name: _vm.$route.name,
          query: Object.assign({}, _vm.$route.query, {
            page: page
          })
        }
      }
    }, [_vm._v(" " + _vm._s(page) + " ")]);
  }), _c('btn-pagination', [_c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "24px"
    }
  })], 1)], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }