var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "height": "100%",
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "cursor": _vm._hasOnClick ? 'pointer' : 'default',
      "background-color": "white",
      "border-radius": ['12px', '20px'],
      "border": "1px solid #f2f2f2",
      "box-shadow": "0px 5px 30px 0px #0000000D"
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "padding": ['16px', '30px 20px 10px 20px'],
      "gap": "16px",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "min-width": ['50px', '70px'],
      "width": ['50px', '70px'],
      "height": ['50px', '70px'],
      "overflow": "hidden",
      "border-radius": "100%",
      "background-color": "lightGray.900"
    }
  }, [_c('c-image', {
    attrs: {
      "object-fit": "cover",
      "src": _vm.getPhotoUser(_vm.photo),
      "alt": _vm.name
    }
  })], 1), _c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px",
      "white-space": "nowrap",
      "flex": "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]), _c('c-box', {
    attrs: {
      "as": "span",
      "border-width": "1px",
      "border-style": "solid",
      "border-radius": "1000px",
      "border-color": _vm.styles.borderColor,
      "background-color": _vm.styles.backgroundColor,
      "padding": ['7px 16px', '9px 22px'],
      "display": "inline-block",
      "vertical-align": "middle"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px",
      "white-space": "nowrap",
      "flex": "1",
      "color": _vm.styles.color
    }
  }, [_vm._v(" " + _vm._s(_vm._labelStatus) + " ")])], 1)], 1), _c('c-box', [_c('BaseDivider', {
    attrs: {
      "margin": "0"
    }
  })], 1), _c('c-box', {
    attrs: {
      "padding": "16px 20px 20px 20px",
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "flex-end",
      "height": "100%",
      "gap": "16px"
    }
  }, [_c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px",
      "white-space": "nowrap",
      "flex": "1",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.programName) + " ")])], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "display": "flex",
      "flex-direction": ['column', 'row'],
      "align-items": ['start', 'end'],
      "justify-content": "space-between",
      "height": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "height": "15px",
      "width": "15px",
      "min-height": "15px",
      "min-width": "15px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/ic-mail.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#555555"
    }
  })], 1), _c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "14px-2",
      "color": "#555555"
    }
  }, [_vm._v(" " + _vm._s(_vm.phone) + " ")])], 1), _c('c-box', {
    attrs: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "height": "15px",
      "width": "15px",
      "min-height": "15px",
      "min-width": "15px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/ic-phone.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#555555"
    }
  })], 1), _c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "14px-2",
      "color": "#555555"
    }
  }, [_vm._v(" " + _vm._s(_vm.email) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }